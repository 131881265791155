import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async generateShareCopy(payload, config) {
      try {
        const { data } = await config.service.createShareCopy(payload)
        this.$root.$socialSharing.open({
          query: `?entity=${data._id}&component=${config.component}&type=${config.type}`,
          type: config.type
        })
      } catch {
        this.addNotification({
          text: 'فشلت عملية المشاركة، يرجى المحاولة مجددا',
          color: 'error'
        })
      }
    },
    async getShareCopy(config) {
      try {
        const { entity } = this.$route.query
        const { data } = await config.service.getShareCopy(entity)
        return data
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل البي دي اف، يرجى المحاولة مجددا',
          color: 'error'
        })
        return {}
      }
    }
  }
}
