<template>
  <div class="page-main-bg">
    <v-container class="main-container">
      <!--tool-bar-->
      <tool-bar v-bind="toolBarProps">
        <slot />
      </tool-bar>
      <!--Filters-->
      <bonds-filters
        v-bind="bondFiltersProps"
        ref="filter"
        @handleFilters="handleFilters"
        @gridView="changeGridView"
      />

      <!--Loader-->
      <loader v-if="isPageLoading" v-bind="loaderProps" class="mt-2" />

      <!--no bonds-->
      <no-data v-else-if="!bonds.length" v-bind="noDataProps" />

      <!--table view-->
      <data-table-bonds
        v-else-if="!gridView"
        v-bind="dataTableBondProps"
        @downloadPDF="setPDFDetails"
        @openDialog="openDialog"
        @showDetails="setDetails"
        @bondDel="checkDeletionBond"
        @sharePdf="sharePdf"
      />

      <!--grid view-->
      <bond-card
        v-else
        v-for="bond in bonds"
        :key="bond._id"
        :bond="bond"
        @bondDel="checkDeletionBond"
        @openDialog="openDialog"
        @showDetails="setDetails"
        @showVersions="showSideVersions"
        @sharePdf="sharePdf"
        @downloadPDF="setPDFDetails"
        class="set__hover"
      />

      <div class="d-flex align-center justify-end ml-md-8 fast-actions">
        <v-btn
          v-if="!isCustomer"
          @click="openAddDialog"
          data-test="open-add-modal"
          class="font-weight-bold fast-actions-btn pa-5 font-12"
          color="primary"
          large
        >
          <img
            class="mx-2"
            src="@/assets/img/svgs/001-writing.svg"
            loading="lazy"
          />
          <span class="mb-1">انشاء سند</span>
        </v-btn>

        <!----------------------------->
        <!-------Fast actions---------->
        <!----------------------------->
        <fastActions />
      </div>
    </v-container>

    <!--sideDetailes-->
    <v-layout v-if="sideDetails && bondDetails">
      <sideBondsDetailes
        v-bind="sideBondDetailsProps"
        @deleteBond="checkDeletionBond"
        @openDialog="openDialog"
        @showDetails="setDetails"
        @exportPdfContract="exportPdfContract"
        @updateNotes="updateBondNotes"
      />
    </v-layout>

    <!--versionDetails-->
    <v-layout v-show="sideVersions">
      <BondSideVersions
        :bond="currentSelectedBondId"
        :contractIntervals="contractIntervals"
        @showVersions="showSideVersions"
        @showDetails="setDetails"
        @closeDetails="sideVersions = false"
      />
    </v-layout>

    <!--Pagination-->
    <div class="text-center mt-6 mb-5" v-if="viewPagination">
      <Pagination :pagination="pagination" @onChange="getBonds()" />
    </div>

    <!--Pdf-->
    <v-layout v-if="pdfStatus">
      <bondPdf
        :headers="internalTableHeader"
        :bonsSubtypes="bonsSubtypes"
        ref="PDF"
        :toBePrint="bondDetails"
        @closePdf="pdfStatus = false"
      />
    </v-layout>

    <!--Add New bond Modal-->
    <AddBondModal
      v-if="dialogState"
      v-bind="addBondModelProps"
      @closed-bond-dialog="closedAddDialog"
    />

    <!--Cancel bond Modal-->
    <cancelBondModel
      v-if="CancelBondDialogState"
      :dialogState="CancelBondDialogState"
      :cancelBtnLoading="cancelBtnLoading"
      :relatedBonds="relatedBonds"
      @deleteBond="deleteBond"
      @changeDialogState="CancelBondDialogState = $event"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { bondsService, contractsService } from '@/services'
import setView from '@/mixins/setView'
import ToolBar from '@/components/listing/ToolBar'
import BondsFilters from '@/components/bonds/BondsFilters'
import DataTableBonds from '@/components/bonds/DataTableBonds.vue'
import BondCard from '@/components/bonds/BondCard'
import sideBondsDetailes from '@/components/bonds/sideBondsDetailes'
import BondSideVersions from '@/components/bonds/BondSideVersions'
import bondPdf from '@/components/bonds/BondPdf.vue'
import Pagination from '@/components/helper/pagination.vue'
import fastActions from '@/components/helper/fastActions'
import loader from '@/components/helper/loader.vue'
import noData from '@/components/helper/noData.vue'
import generateShareCopy from '@/mixins/generateShareCopy'
import dateFormat from '@/mixins/dateFormat.js'
import confirmationMixin from '@/mixins/confirmationMixin.js'
export default {
  name: 'Bonds',
  components: {
    ToolBar,
    BondsFilters,
    loader,
    noData,
    DataTableBonds,
    BondCard,
    sideBondsDetailes,
    BondSideVersions,
    bondPdf,
    Pagination,
    fastActions,
    AddBondModal: () => import('@/components/modals/AddBondModal.vue'),
    CancelBondModel: () => import('@/components/modals/cancelBondModel.vue')
  },
  mixins: [setView, generateShareCopy, dateFormat, confirmationMixin],
  data() {
    return {
      relatedBonds: [],
      CancelBondDialogState: false,
      cancelBtnLoading: false,
      internalTableHeader: [
        {
          text: 'البند Item ',
          value: 'clause',
          align: 'center',
          sortable: false
        },
        {
          text: 'الفترة Period',
          align: 'center',
          sortable: false,
          value: 'duePeriod'
        },
        {
          text: 'المبلغ Amount',
          value: 'amount',
          align: 'center',
          sortable: false
        },
        {
          text: 'الضريبة Vat',
          value: 'taxAmount',
          align: 'center',
          sortable: false
        },
        {
          text: 'الإجمالي Total',
          value: 'total',
          align: 'center',
          sortable: false
        }
      ],
      bondsCount: 0,
      availableStatusesFilters: [],
      clientRoles: {
        renter: 'tenet',
        owner: 'owner'
      },
      exceedTitle:
        'لقد استنفذت العدد المحدد بناءا على الباقة الحالية. يمكنك ترقية باقتك لتتمكن من إجراء إضافات جديدة',
      exceedSubTitle:
        'هذه الخاصية غير متاحة في هذه الباقة. يمكنك ترقية الباقة الحالية لباقة أعلى لتتمكن من إستخدام هذه الخاصية',
      noDataConfig: {
        text: 'لا توجد سندات مضافه حتي الأن',
        subTitle:
          'تكون كل السندات المالية الصادرة المتاحة في هذة القائمة للعرض والمشاركة والتحصيل'
      },
      pageTilte: 'السندات',
      pageSubTilte: 'سند',
      headers: [
        {
          text: 'رقم السند',
          value: 'number',
          align: 'right',
          sortable: false
        },
        {
          text: 'عنوان السند',
          value: 'title',
          align: 'right',
          sortable: false
        },
        {
          text: 'نوع السند',
          value: 'bondType',
          align: 'center',
          sortable: false
        },
        {
          text: 'اسم العقار',
          value: 'realEstate',
          align: 'center',
          sortable: false
        },
        {
          text: 'تاريخ الإنشاء',
          value: 'createdAt',
          align: 'center',
          sortable: false
        },
        {
          text: 'حاله السند',
          value: 'status',
          align: 'center',
          sortable: false
        },
        { text: '', value: 'actions', align: 'center', sortable: false }
      ],
      gridView: true,
      terminated: false,
      pdfStatus: false,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 5,
        count: 0
      },
      isPageLoading: true,
      dialogState: false,
      breadcrumbs: [
        {
          text: 'المالية ',
          disabled: false,
          exact: true,
          link: true,
          to: { path: '/dashboard/contracts' }
        },
        { text: 'السندات', disabled: true }
      ],
      sortOptions: [
        { nameAr: 'الأحدث', nameEn: 'Newest' },
        { nameAr: 'الأقدم', nameEn: 'Oldest' }
      ],
      bonds: [],
      editedBond: {},
      sideDetails: false,
      sideVersions: false,
      currentSelectedBondId: null,
      bondDetails: null,
      bondId: null,
      bondsFilters: {
        sort: 'Newest'
      },
      contractIntervals: []
    }
  },
  async created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    this.handlingContractLogic()
    this.getContractIntervals()
    this.handleOpenSideDetailsUsingNewTab()
  },
  methods: {
    ...mapMutations('appState', ['addNotification', 'toggleDrawer']),
    ...mapActions('accountState', ['getCompanyImages']),
    handleOpenSideDetailsUsingNewTab() {
      if (this.bondDetails === null && !!this.localStorageBond) {
        this.setDetails({
          visible: true,
          bond: this.localStorageBond
        })
      }
    },
    restructureCompanyImages() {
      // restructure images to fit sharing
      const restructuredImages = {}
      for (const key in this.companyImages) {
        restructuredImages[key] = {
          key: this.companyImages[key].key,
          location: this.companyImages[key].path
        }
      }
      return restructuredImages
    },
    async getContractIntervals() {
      try {
        const Response = await contractsService.getContractIntervals()
        this.contractIntervals = Response.data.intervals
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل فترات العقد, برجاء المحاوله لاحقا',
          color: 'error'
        })
      }
    },
    // Pagination - recalculate and handle when clicked on pagination
    recalculatePagination(res) {
      this.pagination.pagesCount = Math.ceil(
        res?.data?.count / this.pagination.pageSize
      )
      this.pagination.count = res?.data?.count ?? 0
    },
    setFilters() {
      let { realEstate, client } = this.$route?.query
      this.$nextTick(() => {
        if (
          realEstate &&
          Object.keys(realEstate.length) &&
          this.$refs?.filter
        ) {
          realEstate = JSON.parse(realEstate)
          this.$refs.filter.allProperties.push(realEstate)
          this.$refs.filter.property = realEstate
          this.bondsFilters.realEstate = realEstate?._id
        } else if (client && Object.keys(client.length) && this.$refs?.filter) {
          client = JSON.parse(client)
          this.$refs.filter.allClients.push(client)
          this.$refs.filter.client = client
          this.bondsFilters.client = client?._id
        }
      })
    },
    async handlingContractLogic() {
      await this.setFilters()
      this.getBonds()
      if (!!this.$router.currentRoute.query.item) {
        const { visible, item } = { ...this.$router.currentRoute.query }
        const query = {
          visible,
          bond: JSON.parse(item),
          terminated: item?.terminated?.isTerminated
        }
        this.setDetails(query)
      }
    },
    // This will handle all cases switching
    switched(data) {
      const { name, value } = data
      this.statusRestore = value

      if (name === 'deleted') {
        return this.allDeletedBonds()
      }
      return this.getBonds()
    },
    openAddDialog() {
      if (!this.hasPermission) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة سندات',
          color: 'error'
        })
        return
      }
      this.dialogState = true
    },
    getBonds() {
      this.isPageLoading = true
      const data = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
      bondsService
        .getAllBonds(this.getBondFilters, data)
        .then((res) => {
          this.bonds = res?.data
            ? res?.data?.bonds.map((bond) => ({
                ...bond,
                checked: false
              }))
            : []
          this.recalculatePagination(res ?? {})
          this.bondsCount = res?.data?.count ?? 0
          this.availableStatusesFilters = res.data?.counts
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isPageLoading = false
        })
    },
    closedAddDialog() {
      this.editedBond = {}
      this.dialogState = false
      this.getBonds()
    },
    checkDeletionBond(val) {
      this.sideDetails = false
      if (!this.$hasPermission('properties', 'bonds', 'delete')) {
        return this.addNotification({
          text: 'ليس لديك صلاحية الغاء سند',
          color: 'error'
        })
      }
      this.bondId = val
      return this.getRelatedBonds()
    },
    async getRelatedBonds() {
      try {
        const { data } = await bondsService.getRelatedBonds(this.bondId)
        this.relatedBonds = data.bonds
        if (!this.relatedBonds.length) {
          this.showConfirmDeletionButton()
        } else {
          this.CancelBondDialogState = true
        }
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل السندات المرتبطة بهذا السند',
          color: 'error'
        })
      }
    },
    showConfirmDeletionButton() {
      this.$root.$confirm
        .open({
          title: 'إلغاء السند',
          message: 'هل أنت متأكد من إلغاء السند, هذه العملية لا يمكن استرجاعها',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteBond()
          }
        })
    },
    async deleteBond() {
      try {
        this.cancelBtnLoading = true
        const { data } = await bondsService.terminatBonds(this.bondId)
        this.addNotification({
          text: 'تم الغاء السند بنجاح',
          color: 'success'
        })
        data.needsApproval && this.showConfirmationPopupMixin()
        this.getBonds()
      } catch (error) {
        let msg = ''
        msg =
          error.response.status === 403
            ? 'لا يمكن إلغاء هذا السند لأنه تم احتسابه في مستحقات المالك '
            : 'حدث خطاء اثناء الغاء السند'
        this.addNotification({
          text: `${msg}`,
          color: 'error'
        })
      } finally {
        this.CancelBondDialogState = false
        this.bondId = null
        this.cancelBtnLoading = false
        this.relatedBonds = []
      }
    },
    openDialog(val) {
      if (!this.$hasPermission('properties', 'bonds', 'update')) {
        return this.addNotification({
          text: 'ليس لديك الصلاحية لتعديل العقد',
          color: 'error'
        })
      }
      this.sideDetails = false
      this.dialogState = true
      this.editedBond = val
      return null
    },
    setDetails({ visible, bond, terminated = bond?.terminated?.isTerminated }) {
      if (!this.$hasPermission('properties', 'bonds', 'list')) {
        return this.addNotification({
          text: 'ليس لديك الصلاحية لعرض السند',
          color: 'error'
        })
      }
      this.sideDetails = visible
      this.bondDetails = bond
      this.terminated = terminated
      visible
        ? this.$router.replace({
            path: '/dashboard/bonds',
            query: { bond: bond.number }
          })
        : this.$router.replace({ path: '/dashboard/bonds' })
      return null
    },
    setPDFDetails({ visible, bond }) {
      this.bondDetails = bond
      this.pdfStatus = visible
    },
    showSideVersions({ visible, id }) {
      if (!this.$hasPermission('properties', 'bonds', 'list')) {
        return this.addNotification({
          text: 'ليس لديك الصلاحية لعرض العقد',
          color: 'error'
        })
      }
      this.sideVersions = visible
      this.currentSelectedBondId = id
      return null
    },
    handleFilters(filters = {}) {
      this.bondsFilters = { ...filters }
      this.pagination.pageNumber = 1
      this.getBonds()
    },
    changeGridView(status) {
      this.updateSetTableView(this.$route.name, status)
      this.gridView = status
    },
    // When click on share internal and external button will trigger this function
    sharePdf(data) {
      const payload = {
        entityType: 'bond',
        entityId: data._id,
        media: this.restructureCompanyImages(),
        env: this.sharedPdfEnvData
      }
      const config = {
        type: 'السند',
        component: 'bondPdf',
        service: bondsService
      }
      this.generateShareCopy(payload, config)
    },
    exportPdfContract(bond) {
      this.sideDetails = false
      this.setPDFDetails({ visible: true, bond })
    },
    updateBondNotes(payload) {
      const bondIndex = this.bonds.findIndex(
        (bond) => bond._id === payload.bondId
      )
      bondIndex !== -1 &&
        this.$set(this.bonds[bondIndex], 'notes', payload.notes)
    }
  },
  watch: {
    customerDefaultRole() {
      this.getBonds()
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user', 'customerDefaultRole']),
    ...mapGetters('accountState', ['isCustomer', 'companyImages']),
    localStorageBond() {
      return JSON.parse(localStorage.getItem('sideDetailsItem'))
    },
    bonsSubtypes() {
      return [
        {
          clause: this.bondDetails?.subType?.nameAr ?? '',
          duePeriod: `${this.bondInstallmentStartDate} - ${this.bondInstallmentEndDate}`,
          amount: this.bondDetails?.bondValue ?? '',
          taxAmount: this.bondDetails?.taxAmount ?? '',
          total: this.bondDetails?.totalAmount ?? '',
          meter:
            this.bondDetails?.contract?.[
              `${this.bondDetails?.subType?.code}Meter`
            ] ?? '-'
        }
      ]
    },
    bondInstallmentStartDate() {
      const startDate = this.bondDetails?.installmentsDate?.startDate
      return startDate ? this.formatDate(startDate) : ''
    },
    bondInstallmentEndDate() {
      const endDate = this.bondDetails?.installmentsDate?.endDate
      return endDate ? this.formatDate(endDate) : ''
    },
    availableFilters() {
      return [
        {
          nameAr: 'الكل',
          nameEn: 'count',
          code: 'count',
          _id: '',
          count: this.bondsCount
        },
        ...this.availableStatusesFilters
      ]
    },
    bondTableHeaders() {
      if (this.$hasPermission('properties', 'bonds', 'delete')) {
        return this.headers
      }
      const filterdHeaders = this.headers.filter(
        (header) => header.value != 'checked'
      )
      return filterdHeaders
    },
    hasPermission() {
      return this.$hasPermission('properties', 'bonds', 'add')
    },
    getBondFilters() {
      const dynamicFilter = {}
      if (this.isCustomer) {
        dynamicFilter.client = this.user._id
        dynamicFilter.clientType = this.customerDefaultRole
      } else {
        dynamicFilter.environment = this.currentEnv._id
      }
      this.bondsFilters = { ...this.bondsFilters, ...dynamicFilter }
      return this.bondsFilters
    },
    toolBarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        length: this.bonds?.length,
        subTilte: this.pageSubTilte,
        title: this.pageTilte
      }
    },
    bondFiltersProps() {
      return {
        availableFilters: this.availableFilters,
        sortOptions: this.sortOptions,
        gridView: this.gridView
      }
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    noDataProps() {
      return {
        noDataText: this.noDataConfig.text,
        noDataSubTitle: this.noDataConfig.subTitle,
        noDataIcon: require('@/assets/img/bonds/bonds.png')
      }
    },
    dataTableBondProps() {
      return {
        bonds: this.bonds,
        gridView: this.gridView,
        headers: this.bondTableHeaders
      }
    },
    sideBondDetailsProps() {
      return {
        terminated: this.terminated,
        item: this.bondDetails,
        contractIntervals: this.contractIntervals,
        headers: this.internalTableHeader,
        bonsSubtypes: this.bonsSubtypes
      }
    },
    addBondModelProps() {
      return {
        dialogState: this.dialogState,
        editedBond: this.editedBond
      }
    },
    viewPagination() {
      return this.pagination.pagesCount > 1
    },
    sharedPdfEnvData() {
      return {
        _id: this.currentEnv._id,
        owner: {
          _id: this.currentEnv.owner._id,
          userType: this.currentEnv.owner.type || this.user.type,
          name: this.currentEnv.owner.name,
          company: this.currentEnv.owner.company || this.user.company,
          phoneNumber: this.currentEnv.owner.phoneNumber
        },
        isOwner: this.currentEnv.emlpoyeeRole === 'owner' ? 1 : 0
      }
    }
  },
  async mounted() {
    const requireCallAssets =
      this.companyImages.companyLogo?.location?.includes('base64') || false
    !requireCallAssets && (await this.getCompanyImages(this.currentEnv._id))
  },
  metaInfo() {
    return {
      title: 'السندات'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
@import '@/styles/material-dashboard/_statistics-cards.scss';
</style>
